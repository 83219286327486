/*! Lightning Design System 2.14.2 */
@charset "UTF-8";
@media (pointer: coarse) and (hover: none){
  body{
    font-size:1rem;
  }

  .slds-button.slds-accordion__summary-action{
    line-height:1.875rem;
  }

  .slds-button{
    line-height:2.625rem;
    font-weight:700;
  }

  .slds-button_icon,
.slds-button--icon,
.slds-button_icon-inverse,
.slds-button--icon-inverse,
.slds-button_icon-container,
.slds-button--icon-container,
.slds-button_icon-border,
.slds-button--icon-border,
.slds-button_icon-border-filled,
.slds-button--icon-border-filled,
.slds-button_icon-border-inverse,
.slds-button--icon-border-inverse,
.slds-button_icon-more,
.slds-button--icon-more,
.slds-button_icon-error,
.slds-button--icon-error{
    width:2.75rem;
    height:2.75rem;
  }

  .slds-button_icon-container,
.slds-button--icon-container,
.slds-button_icon-border,
.slds-button--icon-border,
.slds-button_icon-border-filled,
.slds-button--icon-border-filled,
.slds-button_icon-border-inverse,
.slds-button--icon-border-inverse,
.slds-button_icon-brand,
.slds-button_icon-more,
.slds-button--icon-more,
.slds-button_icon-container-more,
.slds-button--icon-container-more{
    width:2.75rem;
    height:2.75rem;
  }

  .slds-button_icon-small,
.slds-button--icon-small{
    width:2.75rem;
    height:2.75rem;
  }

  .slds-button_icon-x-small,
.slds-button--icon-x-small{
    width:2.75rem;
    height:2.75rem;
  }

  .slds-button_icon-xx-small,
.slds-button--icon-xx-small{
    width:2.75rem;
    height:2.75rem;
  }

  .slds-button_icon-more,
.slds-button--icon-more{
    width:2.75rem;
    height:2.75rem;
  }

  .slds-card{
    border-color:transparent;
    border-radius:0;
  }

  .slds-card__header-title{
    font-size:1.125rem;
    font-weight:700;
  }

  .slds-checkbox_button-group,
.slds-checkbox--button-group{
    display:block;
  }
  .slds-checkbox_button-group .slds-checkbox_button,
.slds-checkbox_button-group .slds-checkbox--button,
.slds-checkbox--button-group .slds-checkbox_button,
.slds-checkbox--button-group .slds-checkbox--button{
    display:block;
  }
  .slds-checkbox_button-group .slds-checkbox_button + .slds-checkbox_button,
.slds-checkbox_button-group .slds-checkbox--button + .slds-checkbox--button,
.slds-checkbox--button-group .slds-checkbox_button + .slds-checkbox_button,
.slds-checkbox--button-group .slds-checkbox--button + .slds-checkbox--button{
    border-left:0;
    border-top:1px solid #dddbda;
  }
  .slds-checkbox_button-group .slds-checkbox_button:first-child > .slds-checkbox_button__label,
.slds-checkbox_button-group .slds-checkbox--button:first-child > .slds-checkbox--button__label,
.slds-checkbox--button-group .slds-checkbox_button:first-child > .slds-checkbox_button__label,
.slds-checkbox--button-group .slds-checkbox--button:first-child > .slds-checkbox--button__label{
    border-radius:0.25rem 0.25rem 0 0;
  }
  .slds-checkbox_button-group .slds-checkbox_button:last-child > .slds-checkbox_button__label,
.slds-checkbox_button-group .slds-checkbox--button:last-child > .slds-checkbox--button__label,
.slds-checkbox--button-group .slds-checkbox_button:last-child > .slds-checkbox_button__label,
.slds-checkbox--button-group .slds-checkbox--button:last-child > .slds-checkbox--button__label{
    border-radius:0 0 0.25rem 0.25rem;
  }
  .slds-checkbox_button-group .slds-checkbox_button__label,
.slds-checkbox_button-group .slds-checkbox--button__label,
.slds-checkbox--button-group .slds-checkbox_button__label,
.slds-checkbox--button-group .slds-checkbox--button__label{
    display:block;
    text-align:center;
  }

  .slds-checkbox_button,
.slds-checkbox--button{
    line-height:2.625rem;
  }

  .slds-checkbox-button{
    width:2.75rem;
    height:2.75rem;
  }

  .slds-checkbox_add-button,
.slds-checkbox--add-button{
    width:2.75rem;
    height:2.75rem;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
        -ms-flex-align:center;
            align-items:center;
    -webkit-box-pack:center;
        -ms-flex-pack:center;
            justify-content:center;
  }
  .slds-checkbox_add-button .slds-checkbox_faux,
.slds-checkbox_add-button .slds-checkbox--faux,
.slds-checkbox--add-button .slds-checkbox_faux,
.slds-checkbox--add-button .slds-checkbox--faux{
    width:2rem;
    height:2rem;
  }

  .slds-checkbox_toggle,
.slds-checkbox--toggle{
    padding:0.125rem 0;
  }
  .slds-checkbox_toggle .slds-form-element__label,
.slds-checkbox--toggle .slds-form-element__label{
    -webkit-box-align:start;
        -ms-flex-align:start;
            align-items:flex-start;
    font-size:1rem;
  }
  .slds-checkbox_toggle [type=checkbox] + .slds-checkbox_faux_container,
.slds-checkbox_toggle [type=checkbox] + .slds-checkbox--faux_container,
.slds-checkbox--toggle [type=checkbox] + .slds-checkbox_faux_container,
.slds-checkbox--toggle [type=checkbox] + .slds-checkbox--faux_container{
    font-size:0.75rem;
  }

  .slds-checkbox .slds-checkbox_faux,
.slds-checkbox .slds-checkbox--faux{
    width:1.5rem;
    height:1.5rem;
    -ms-flex-negative:0;
        flex-shrink:0;
  }
  .slds-checkbox .slds-checkbox__label{
    display:-webkit-inline-box;
    display:-ms-inline-flexbox;
    display:inline-flex;
    -webkit-box-align:center;
        -ms-flex-align:center;
            align-items:center;
    vertical-align:middle;
    min-height:2.75rem;
  }
  .slds-checkbox .slds-checkbox__label .slds-form-element__label{
    display:-webkit-inline-box;
    display:-ms-inline-flexbox;
    display:inline-flex;
    font-size:1rem;
  }
  .slds-checkbox [type=checkbox]:checked + .slds-checkbox_faux:after, .slds-checkbox [type=checkbox]:checked + .slds-checkbox--faux:after, .slds-checkbox [type=checkbox]:checked ~ .slds-checkbox_faux:after, .slds-checkbox [type=checkbox]:checked ~ .slds-checkbox--faux:after, .slds-checkbox [type=checkbox]:checked + .slds-checkbox__label .slds-checkbox_faux:after, .slds-checkbox [type=checkbox]:checked + .slds-checkbox__label .slds-checkbox--faux:after{
    height:0.375rem;
    width:0.75rem;
    margin-top:-1px;
  }
  .slds-checkbox [type=checkbox]:indeterminate + .slds-checkbox_faux:after, .slds-checkbox [type=checkbox]:indeterminate + .slds-checkbox--faux:after, .slds-checkbox [type=checkbox]:indeterminate ~ .slds-checkbox_faux:after, .slds-checkbox [type=checkbox]:indeterminate ~ .slds-checkbox--faux:after, .slds-checkbox [type=checkbox]:indeterminate + .slds-checkbox__label .slds-checkbox_faux:after, .slds-checkbox [type=checkbox]:indeterminate + .slds-checkbox__label .slds-checkbox--faux:after{
    width:0.75rem;
  }
  .slds-checkbox.slds-checkbox_stacked .slds-form-element__label{
    font-size:0.875rem;
  }

  .slds-checkbox_standalone{
    min-height:2.75rem;
  }
  .slds-checkbox_standalone [type=checkbox]{
    width:2.75rem;
    height:2.75rem;
    position:absolute;
    top:50%;
    -webkit-transform:translateY(-50%);
            transform:translateY(-50%);
  }
  .slds-checkbox_standalone .slds-checkbox_faux{
    position:absolute;
    top:50%;
    -webkit-transform:translateY(-50%);
            transform:translateY(-50%);
  }

  .slds-listbox_horizontal li + li,
.slds-listbox--horizontal li + li{
    padding-left:0.25rem;
  }

  .slds-listbox__option-header{
    font-size:1rem;
  }

  .slds-listbox_vertical .slds-listbox__option_plain,
.slds-listbox_vertical .slds-listbox__option--plain,
.slds-listbox--vertical .slds-listbox__option_plain,
.slds-listbox--vertical .slds-listbox__option--plain{
    line-height:2.75rem;
    padding-top:0;
    padding-bottom:0;
    -webkit-box-align:center;
        -ms-flex-align:center;
            align-items:center;
  }
  .slds-listbox_vertical .slds-listbox__option_plain .slds-media__figure,
.slds-listbox_vertical .slds-listbox__option--plain .slds-media__figure,
.slds-listbox--vertical .slds-listbox__option_plain .slds-media__figure,
.slds-listbox--vertical .slds-listbox__option--plain .slds-media__figure{
    margin-right:0.5rem;
  }

  .slds-listbox_object-switcher,
.slds-listbox--object-switcher{
    padding:0;
  }

  .slds-combobox_object-switcher .slds-combobox__input{
    font-size:1rem;
  }

  .slds-listbox_selection-group{
    height:2.75rem;
  }
  .slds-listbox_selection-group .slds-listbox{
    padding:0 0.25rem 0.375rem;
  }
  .slds-listbox_selection-group .slds-listbox-item{
    padding:0.375rem 0.125rem 0;
  }

  .slds-has-inline-listbox .slds-combobox__input,
.slds-has-object-switcher .slds-combobox__input{
    line-height:2.5rem;
    min-height:2.75rem;
  }

  .slds-has-inline-listbox [role=listbox]{
    padding:0 0.375rem;
  }

  .slds-th__action{
    padding-right:1.25rem;
  }

  .slds-form-element__label{
    display:-webkit-inline-box;
    display:-ms-inline-flexbox;
    display:inline-flex;
    -webkit-box-align:center;
        -ms-flex-align:center;
            align-items:center;
    min-height:2rem;
    padding-top:0;
    padding-right:0;
    margin-bottom:0;
    font-size:0.875rem;
  }

  .slds-form-element__icon .slds-button_icon{
    height:2rem;
  }
  .slds-form-element__control .slds-form-element__icon .slds-button_icon{
    height:2.75rem;
  }

  .slds-form-element__icon{
    padding-top:0;
  }

  .slds-form-element_edit .slds-form-element__static,
.slds-form-element--edit .slds-form-element__static{
    width:calc(100% - 2.75rem);
  }

  .slds-form-element_readonly .slds-icon{
    width:1.5rem;
    height:1.5rem;
  }

  .slds-form-element__static{
    font-size:1rem;
  }
  .slds-form-element_horizontal .slds-button_icon,
.slds-form_horizontal .slds-form-element .slds-button_icon,
.slds-form_stacked .slds-form-element_horizontal .slds-button_icon{
    vertical-align:top;
  }
  .slds-form-element_stacked .slds-form-element__icon,
.slds-form_stacked .slds-form-element .slds-form-element__icon,
.slds-form_horizontal .slds-form-element_stacked .slds-form-element__icon{
    padding-top:0;
  }
  .slds-input{
    line-height:2.625rem;
  }
  .slds-input[readonly]{
    font-size:1rem;
  }
  .slds-input[type=text], .slds-input[type=email], .slds-input[type=url], .slds-input[type=tel]{
    -webkit-appearance:none;
  }
  .slds-input[type=date], .slds-input[type=datetime], .slds-input[type=datetime-local], .slds-input[type=time], .slds-input[type=week], .slds-input[type=month]{
    display:-webkit-inline-box;
    display:-ms-inline-flexbox;
    display:inline-flex;
  }
  .slds-input[type=date], .slds-input[type=datetime-local], .slds-input[type=month], .slds-input[type=time]{
    height:2.75rem;
  }
  .slds-input-has-icon .slds-input__icon{
    width:1rem;
    height:1rem;
    margin-top:-0.5rem;
  }
  .slds-input-has-icon_left .slds-input__icon, .slds-input-has-icon--left .slds-input__icon{
    left:0.5rem;
  }
  .slds-input-has-icon_right .slds-input__icon, .slds-input-has-icon--right .slds-input__icon{
    right:0.5rem;
  }
  .slds-input-has-icon_left-right .slds-input__icon_left,
.slds-input-has-icon_left-right .slds-input__icon--left, .slds-input-has-icon--left-right .slds-input__icon_left,
.slds-input-has-icon--left-right .slds-input__icon--left{
    left:0.5rem;
  }
  .slds-input-has-icon_left-right .slds-input__icon_right,
.slds-input-has-icon_left-right .slds-input__icon--right, .slds-input-has-icon--left-right .slds-input__icon_right,
.slds-input-has-icon--left-right .slds-input__icon--right{
    right:0.5rem;
  }
  .slds-input__icon-group_right .slds-input__spinner{
    right:calc(1.5rem + 0.25rem);
  }
  .slds-map_container{
    padding:1rem 1rem 0;
  }
  .slds-map{
    min-width:21.75rem;
  }
  .slds-has-coordinates{
    max-height:none;
  }
  .slds-coordinates__title{
    font-size:1.125rem;
  }
  .slds-dropdown{
    padding:0;
    font-size:1rem;
  }
  .slds-dropdown__item > a{
    line-height:2.75rem;
    padding:0 0.75rem;
  }
  .slds-dropdown__header{
    font-size:1rem;
  }
  .slds-modal .slds-modal__title{
    font-weight:700;
  }
  .slds-modal__close{
    width:2.75rem;
    height:2.75rem;
    top:-2.75rem;
    right:0;
  }
  .slds-path__nav .slds-is-current:first-child .slds-path__link{
    height:calc(2.75rem - 0.25rem);
  }
  .slds-path__nav .slds-is-active:first-child .slds-path__link{
    height:2.75rem;
  }
  .slds-path__item{
    margin-left:0.5rem;
    margin-right:0.5625rem;
  }
  .slds-path__item:before, .slds-path__item:after{
    left:-0.3125rem;
    right:-0.375rem;
  }
  .slds-path__item:before{
    height:calc((2.75rem / 2) + 0.0625rem);
  }
  .slds-path__item:after{
    height:1.375rem;
  }
  .slds-path__item:first-child:before, .slds-path__item:first-child:after{
    left:1.625rem;
  }
  .slds-path__item:last-child:before, .slds-path__item:last-child:after{
    right:1.125rem;
  }
  .slds-path__link{
    height:2.75rem;
  }
  .slds-pill{
    line-height:1.875rem;
    padding:0 0.5rem;
  }
  .slds-pill + .slds-pill{
    margin-left:0.25rem;
  }
  .slds-pill__container, .slds-pill-container, .slds-pill_container{
    padding:0.25rem;
  }
  .slds-pill__label{
    font-size:0.875rem;
  }
  .slds-pill__icon ~ .slds-pill__action, .slds-pill__icon_container ~ .slds-pill__action{
    padding-left:calc(1.25rem + 0.5rem);
  }
  .slds-pill_link,
.slds-pill--link{
    padding:0;
  }
  .slds-pill_link .slds-pill__icon_container,
.slds-pill--link .slds-pill__icon_container{
    left:0.25rem;
  }
  .slds-pill_link .slds-pill__remove,
.slds-pill--link .slds-pill__remove{
    right:0;
    width:2rem;
    height:2rem;
    margin:0;
    border:0;
  }
  .slds-pill_link__icon ~ .slds-pill__action, .slds-pill_link__icon_container ~ .slds-pill__action,
.slds-pill--link__icon ~ .slds-pill__action,
.slds-pill--link__icon_container ~ .slds-pill__action{
    padding-left:calc(1.25rem + 0.5rem);
  }
  .slds-pill__action{
    padding:0;
    padding-left:0.5rem;
    padding-right:2rem;
  }
  .slds-popover_prompt__heading{
    font-size:1.25rem;
  }
  .slds-progress__item{
    position:relative;
    -webkit-box-align:center;
        -ms-flex-align:center;
            align-items:center;
    min-height:2.75rem;
  }
  .slds-progress__item .slds-button:before{
    position:absolute;
    width:calc(100% + 8px);
    height:2.75rem;
    content:"";
  }
  .slds-progress__marker{
    width:1.25rem;
    height:1.25rem;
    line-height:1;
  }
  .slds-progress__marker_icon{
    width:2rem;
    height:2rem;
  }
  .slds-progress__marker_icon .slds-button__icon{
    width:1.25rem;
    height:1.25rem;
  }
  .slds-progress_vertical .slds-progress__item:before, .slds-progress_vertical .slds-progress__item:after{
    left:calc((1.25rem / 2) - 1px);
  }
  .slds-progress_vertical .slds-progress__item_content{
    font-size:0.875rem;
  }
  .slds-progress_vertical .slds-progress__marker{
    width:1.25rem;
  }
  .slds-progress_vertical .slds-progress__marker .slds-icon{
    width:1.25rem;
    height:1.25rem;
  }
  .slds-radio_button-group,
.slds-radio--button-group{
    display:block;
  }
  .slds-radio_button-group .slds-radio_button,
.slds-radio_button-group .slds-radio--button,
.slds-radio--button-group .slds-radio_button,
.slds-radio--button-group .slds-radio--button{
    display:block;
  }
  .slds-radio_button-group .slds-radio_button + .slds-radio_button,
.slds-radio_button-group .slds-radio--button + .slds-radio--button,
.slds-radio--button-group .slds-radio_button + .slds-radio_button,
.slds-radio--button-group .slds-radio--button + .slds-radio--button{
    border-left:0;
    border-top:1px solid #dddbda;
  }
  .slds-radio_button-group .slds-radio_button:first-child > .slds-radio_button__label,
.slds-radio_button-group .slds-radio--button:first-child > .slds-radio--button__label,
.slds-radio--button-group .slds-radio_button:first-child > .slds-radio_button__label,
.slds-radio--button-group .slds-radio--button:first-child > .slds-radio--button__label{
    border-radius:0.25rem 0.25rem 0 0;
  }
  .slds-radio_button-group .slds-radio_button:last-child > .slds-radio_button__label,
.slds-radio_button-group .slds-radio--button:last-child > .slds-radio--button__label,
.slds-radio--button-group .slds-radio_button:last-child > .slds-radio_button__label,
.slds-radio--button-group .slds-radio--button:last-child > .slds-radio--button__label{
    border-radius:0 0 0.25rem 0.25rem;
  }
  .slds-radio_button-group .slds-radio_button__label,
.slds-radio_button-group .slds-radio--button__label,
.slds-radio--button-group .slds-radio_button__label,
.slds-radio--button-group .slds-radio--button__label{
    display:block;
    text-align:center;
  }
  .slds-radio_button,
.slds-radio--button{
    line-height:2.69rem;
  }
  .slds-radio .slds-radio__label{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
        -ms-flex-align:center;
            align-items:center;
    min-height:2.75rem;
  }
  .slds-radio .slds-radio_faux,
.slds-radio .slds-radio--faux{
    width:1.5rem;
    height:1.5rem;
  }
  .slds-radio .slds-form-element__label{
    display:-webkit-inline-box;
    display:-ms-inline-flexbox;
    display:inline-flex;
    font-size:1rem;
  }
  .slds-slider{
    -webkit-box-align:center;
        -ms-flex-align:center;
            align-items:center;
    margin-top:0.5rem;
    min-height:2rem;
  }
  .slds-slider__range::-webkit-slider-thumb{
    height:2rem;
    width:2rem;
    margin-top:calc(((2rem / 2) - (4px / 2)) * -1);
  }
  .slds-slider__range::-moz-range-thumb{
    height:2rem;
    width:2rem;
  }
  .slds-slider__range::-ms-thumb{
    height:2rem;
    width:2rem;
  }
  .slds-slider_vertical{
    -webkit-box-align:initial;
        -ms-flex-align:initial;
            align-items:initial;
  }
  .slds-slider_vertical .slds-slider__range{
    margin-left:calc((2rem - 1rem) / 2);
  }
  .slds-slider_vertical .slds-slider__value{
    left:calc((2rem - 1rem) / 2);
  }
  .slds-tabs-mobile__item .slds-button:active,
.slds-tabs-mobile__item .slds-button:hover{
    color:currentColor;
  }
  .slds-text-body_regular,
.slds-text-body--regular{
    font-size:1rem;
  }
  .slds-text-body_small,
.slds-text-body--small{
    font-size:0.875rem;
  }
  .slds-text-heading_large,
.slds-text-heading--large{
    font-size:2rem;
  }
  .slds-text-heading_medium,
.slds-text-heading--medium{
    font-size:1.5rem;
  }
  .slds-text-heading_small,
.slds-text-heading--small{
    font-size:1.25rem;
  }
}
@media (pointer: coarse) and (hover: none) and (min-width: 48em){
  .slds-form-element_horizontal .slds-form-element__label,
.slds-form_horizontal .slds-form-element .slds-form-element__label,
.slds-form_stacked .slds-form-element_horizontal .slds-form-element__label{
    display:block;
    max-width:calc(33% - 2.75rem);
    -ms-flex-preferred-size:calc(33% - 2.75rem);
        flex-basis:calc(33% - 2.75rem);
    padding-top:0.25rem;
  }
  .slds-form-element_horizontal .slds-form-element__control,
.slds-form_horizontal .slds-form-element .slds-form-element__control,
.slds-form_stacked .slds-form-element_horizontal .slds-form-element__control{
    display:block;
    min-height:0;
  }
  .slds-form-element_horizontal .slds-form-element__icon,
.slds-form_horizontal .slds-form-element .slds-form-element__icon,
.slds-form_stacked .slds-form-element_horizontal .slds-form-element__icon{
    padding-top:0;
  }
  .slds-form-element_horizontal .slds-checkbox__label,
.slds-form_horizontal .slds-form-element .slds-checkbox__label,
.slds-form_stacked .slds-form-element_horizontal .slds-checkbox__label{
    display:block;
  }
}